.allCar {
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* height: 100%; */
    /* z-index: 15; */
  }

  .arrow-button-left {
    position: relative;
    background-color: transparent;
    /* height: 4%;
    width: 4%; */
    border: None;
  }

  .arrow-button-left::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 25px;
    border-color: transparent rgb(0, 0, 0, .3) transparent transparent;
    border-style: solid;
    left: -40px;
    bottom: -40px;
  }

  .arrow-button-right {
    position: relative;
    background-color: transparent;
    border: None;
  }

  .arrow-button-right::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 25px;
    border-color: transparent transparent transparent rgb(0, 0, 0, .3);
    border-style: solid;
    right: -40px;

  }


  .homeTitle {
    font-size: 44px;
    font-weight: 700;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif
  }

  .sliderImages {
    width: 45vw;
    height:30vw;
    /* max-width: 700px;
    max-height: 550px; */
    border-radius: 10px;
    box-shadow: 0 0 50px 50px white inset;
    object-fit: cover
  }