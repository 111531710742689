


.gitLogo {
    height: 30px;
    width: 30px;
    padding-top:7px;
    padding-right: 5px;

}

.inLogo {
    height: 30px;
    width: 30px;
    padding-top:7px;
    padding-left: 5px;
}

.oneDishButton {
    background-color: hsl(0, 0%, 50%, .04);
    border: 1px solid black;
    border: 1px solid black;
    padding: 8px;
    margin: 8px;
    border-radius: 10px;
    font-size: 16px;
}