.topReviewTitle {
    font-size: 64px;
    text-align: center;
    padding: 3%
}

.topReviewCard {
    display: flex;
    flex-direction: column;
    /* width: 30%;
    height: 20%; */
    width: 20em;
    height: 25em;
    align-items: center;
    justify-content: center;
    padding: 1%;
    margin: 1%;
    border-radius: 10px;
    box-shadow: inset 2px 2px 30px rgb(0 0 0 / 10%)
}

.topReviewCards {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    margin-bottom: 2%;
}

.topReviewImg {
    /* width: 20vw; */
    /* height: 20vh; */
    width: 70%;
    height: 65%;
    padding: 2%;
    object-fit: cover;
    border-radius: 15px;
}

.topReviewButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(0, 0%, 50%, .04);
  border: 1px solid black;
  padding: 3%;
  margin: 3%;
  border-radius: 10px;
  height: 30px;
  width: 100px;
  white-space: nowrap;
  text-decoration: none;

}

.topReviewButton:hover {
  background-color: rgb(103, 178, 197, .2);
}

.topReviewButtonModal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(0, 0%, 50%, .04);
  border: 1px solid black;
  padding: 3%;
  margin-top: 15%;
  border-radius: 10px;
  height: 30px;
  width: 100px;
  white-space: nowrap;
  text-decoration: none;

}

.topReviewButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 15%; */
}

.topReviewText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
  margin: 2%;
  word-break: break-word;
  overflow-y: auto;
  text-align: center;
}

.totalTopReviews {
  height: 100%;
}

.deleteModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



HTML CSSResult Skip Results Iframe
EDIT ON
bodY {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #000; */
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}