/* TODO Add site wide styles */


 body, html{
    margin: 0px;
    padding: 0px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }



.button {
    background-color: hsl(0, 0%, 100%);
    border: 1px solid black;
    border: 1px solid black;
    padding: 12px;
    margin: 8px;
    border-radius: 10px;
    font-size: 16px;
}

.button:hover {
    background-color: rgb(103, 178, 197, .2);
}


.footer {

    display: flex;
    position: sticky;
    bottom: 0px;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid grey;
    background-color: white;
    max-height: 42px;
    width: 100%;
    min-height: 40px;
    /* height: 6vh; */
    z-index: 15;
}

button{
    cursor: pointer;
}