.searchForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45vw;
}

.actualInputSearch {
    /* width: 45vw; */
    width: 95%;
    height: 30px;
    border-radius: 10px;
    /* width: 80%; */
}

.oneFormInputSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.search {
    display: flex;
    flex-direction: column;
    margin: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25%;
  }

  .totalSearchForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45vw
  }

  .dishName {
    color: rgb(44, 64, 74);
    font-size: 32px;
    padding: 20px;
  }

  .subTitle {
    font-size: 42px;
    padding: 0px;
    margin-top: 50px;
    text-shadow: 2px 2px 2px white;
  }

  .oneErrorSearch {
    padding: 10px;
    font-size: 14px;
    margin-top: 2px;
}

.alignSearch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

}

.buttonSearch {
  background-color: hsl(0, 0%, 100%);
  border: 1px solid black;
  border: 1px solid black;
  /* padding: 12px; */
  /* margin: 8px; */
  border-radius: 10px;
  /* height: 34px; */
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 34px;
  /* font-size: fit-content; */
}

.buttonSearch:hover {
  background-color: rgb(103, 178, 197, .2);
}

.buttonContainer {
  width: 20%;
  height: 34px;
  display: flex;
  align-items: center;
  margin-left: 2%;
}

.inputAndBut {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-bottom: 3%;
}

