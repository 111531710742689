.createDishForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.spotSubmitButton {
    width: 40%;
    height: 50px;
    min-height: 50px;
    border-radius: 2px;
    background-color: rgb(215, 4, 102);
    color: white;
    margin-top: 10px;
    border-radius: 5px;
}

.formInputsCreate {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.oneFormInput {
    padding: 2px;
    display: flex;
    align-items: center;
}

.oneFormInputRating {
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.oneFormInputDescrip {
    padding: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    flex-direction: row;
}

.oneFormInputHidden {
    height: 0px;
}

.imageInput {
    /* display: flex;
    flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    text-align: center;
    margin: 2%;
}

.formPaddingDes {
    padding: 3px;
    width: 100%;
    display: flex;
    justify-content: center;

}

.textAreaInputCreate {
    width: 100%;
    height: 25px;
    /* resize: none; */
    border-radius: 4px;
    padding: 4px;
    border: 1px solid black;
}

.formPadding {
    padding: 3px;
}

.actualInput {
    border-radius: 4px;
    border: 1px solid black;
}

.actualInputDescrip {
    border-radius: 4px;
    border: 1px solid black;
    width: 50%;
    height: 5vh;
    word-break: break-word;
    overflow-x:scroll
}


.title {
    margin-left: 35px;
    /* margin-bottom:20px */
}

/* .errors {
    display: flex;
    flex-direction: column;
    justify-content:end;
} */


.errorsCreate {
    display: flex;
    flex-direction: column;
    justify-content:end;
    margin-top: 1%;
    /* box-shadow: 2px 2px 30px rgba(141, 8, 8, 0.1); */
    border: 1px solid rgb(255, 0, 0, .4);
    border-radius: 10px;
    padding: 1%;
}


.oneError {
    font-size: 12px;
    margin-top: 2px;
}