.allHomeDish {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 80%;

    justify-content: center;
}

.oneHomeDish {
height: 100%;
width: 100%;
padding: 2%;
border-radius: 20px;
object-fit: cover
}

.oneHomeDish:hover {
    border: blue 1px solid;
  }

.re {
margin: 2%;
width: 20vw;
height: 30vh;
}