.buttonFilter {

        background-color: hsl(0, 0%, 50%, .04);
        border: 1px solid black;
        border: 1px solid black;
        padding: 12px;
        margin: 8px;
        border-radius: 10px;
        margin-bottom: 30px;
        font-size: 16px;

}

.createFilterForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60vh;
    }

    #modal-content {
        height: 70vh;
    }

    .authSubmitButton {
        width: 40%;
        height: 50px;
        min-height: 50px;
        border-radius: 2px;
        background-color: rgb(215, 4, 102);
        color: white;
        margin-top: 10px;
        border-radius: 5px;
    }

    .formInputs {
        display: flex;
        flex-direction: column;
    }

    .oneFormInputFilter {
        padding: 1%;
        margin-top: 5%;
    }

    .textAreaInputCreate {
        width: 100%;
        height: 25px;
        /* resize: none; */
        border-radius: 4px;
        padding: 4px;
        border: 1px solid black;
    }

    .formPaddingFilter {
        /* padding: 3px; */
        width: 0px;
        height: 0px;
    }

    .actualInput {
        border-radius: 4px;
        border: 1px solid black;
    }

    .filterTitle {
        /* margin-left: 35px; */
        /* margin-bottom:20px */
        font-size: 35px;
        font-weight: bold;
        text-align: center;
    }

    .errorsFilter {
        display: flex;
        flex-direction: column;
        justify-content:end;
        margin-top: 20px;
        /* box-shadow: 2px 2px 30px rgba(141, 8, 8, 0.1); */
        border: 1px solid rgb(255, 0, 0, .4);
        border-radius: 10px;
        padding: 2%;
    }

    .oneError {
        font-size: 10px;
        margin-top: 2px;
    }

    .instruct {
        font-style: italic;
        font-weight: bold;
    }

    .buttonFilterRound {
        margin-top: 2%;
        margin-bottom: 2%;
        display:block;
        height: 75px;
        width: 75px;
        /* height: 10%;
        width: 10%; */
        border-radius: 50%;
        border: 1px solid black;
    }

    .buttonFilterRound:hover {
        background-color: rgb(103, 178, 197, .2);
    }

.filterAddress {
    padding: 0%;
    margin-top: 1%;
    margin-bottom: 1%;
    text-align: left;
}

.filterFrame {
    height:60%;
    width: 70%;
    margin-top: 4%;
    border-radius: 10px;
}

#autoInput {
    width: 40em;
    border-radius: 5px;
    height: 20px;
    border: 1px solid black;
}

.suggestion-item {
    border: 1px solid black;

}

.suggestion-item:hover {
    border: 1px solid black;
    background-color: #e6e2e2ed;
}

.suggestText {
    font-size: 11px;
}

.addressAndAuto {
    display: flex;
    flex-direction: column;
}

.dishNameSpace {
    margin-top: 3%;
}