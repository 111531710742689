.authForms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.authSubmitButton {
    width: 40%;
    height: 50px;
    min-height: 50px;
    border-radius: 2px;
    background-color: rgb(215, 4, 102);
    color: white;
    margin-top: 10px;
    border-radius: 5px;
}


.authSubmitButton:hover {
    background-color: rgb(215,4,102, .5);
}



.formInputs {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.oneFormInput {
    padding: 4px;
    display: flex;
    justify-content: space-between
}

.textAreaInputCreate {
    width: 100%;
    height: 25px;
    /* resize: none; */
    border-radius: 4px;
    padding: 4px;
    border: 1px solid black;
}

.formPadding {
    padding: 3px;
}

.actualInput {
    border-radius: 4px;
    border: 1px solid black;
    margin-left: 1%;
    height: 20px;
}

.title {
    margin-left: 35px;
    /* margin-bottom:20px */
}

.errors {
    display: flex;
    flex-direction: column;
    justify-content:end;
    /* margin-top: 10px; */
}

.oneError {
    font-size: 10px;
    margin-top: 2px;
}