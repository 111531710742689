.navAndTitle {
    top: 0px;
    z-index: 0;
    position: sticky;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: rgb(70, 67, 67) solid 1px;
    background-color: white;
    padding: .5%;
    z-index: 15;
}

.homeTitle1 {
    margin: 10px;
    margin-left: 5px;
    font-size: 30px;
    color: black;
}

.homeTitle1:hover {
    color: rgb(121, 144, 150);
}

.symbolNav {
    width: 60px;
    height: 60px;
    margin-left: 25px;
}


.titleAndLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.totalNav {
    width: 40%;
    display: flex;
    justify-content: center;
}

.navy {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.smallNavy {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    /* justify-content: center; */
    /* margin-right: 2%; */
}

.profileLinkz {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 22px;
    border: none;
    background-color:rgb(255, 255, 255, 0);
    color: rgb(91,34,143);
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.profileLinkz:hover {
    color: rgb(121, 144, 150);
}