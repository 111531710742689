
  .totalHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url(https://i.fbcd.co/products/original/d980008089477695e011ea6e0d5b105bacd85ab34db778bf1d4c658ffeedb331.jpg); */
    /* opacity: .8; */
    /* justify-content: center; */
    width: 100%;
    height: 100vh;
  }

  .jesus {
    height: 100%;
  }

  .filter {
    display: flex;
    margin: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 25%;
  }

  .title1 {
    margin-top: 20px;
    color: rgb(2, 50, 73);
    font-size: 64px;
    font-weight: bold;
  }

  .mission {
    width: 44vw;
    margin-top: 2%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    margin-bottom: 1%;
  }

  #modal-content {
    position: absolute;
    background-color: white;
    height: 60vh;
    width: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.footerIsh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 6%;
  border-top: 1px solid black;
}


.footerItems {
  margin-left: 2%;
  margin-right: 2%;
}