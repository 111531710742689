.restaurantMaps {
width: 12vw;
height: 35vh;
/* width: 50%;
height: 100%; */
}

.restaurantCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.restaurantCard1 {
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    margin: 2%;
    box-shadow: inset 2px 2px 30px rgba(0, 0, 0, 0.1);
    padding: 2%;
    border-radius: 10px;
}

.splitImg {
  /* height: 50%;
  width: 50%; */
  width: 12vw;
  height: 35vh;
  object-fit: cover
}

.splitGoogDish {
  display: flex;
  flex-direction: row;

}

.restaurantCard1:hover {
  border: blue 1px solid;
}


.restTitle {
    font-size:2em;
    padding: 2%;
    text-align: center;
}

.totalRestTitle {
    padding-top: 2%;
    font-size: 4em;
    text-align: center;
}










HTML CSSResult Skip Results Iframe
EDIT ON
bodY {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #000; */
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}